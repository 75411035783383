body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, .bg-light {
    background-color: #fcfcfd !important;
}

.icon {
    /* Fixes icon alignment */
    width: 1em;
    height: 1em;
    vertical-align: -.1em;
    display: inline-block;
}

.btn-load-more {
    padding: 0 0.25rem;
    font-size: 75%;
}

.list-style-simple {
    list-style-type: none;
}

.flip-vertical {
    transform: scaleY(-1);
}

.flip-horizontal {
    transform: scaleX(-1);
}

.rotate-clockwise {
    transform: rotate(90deg);
}

.rotate-anticlockwise {
    transform: rotate(-90deg);
}

.hoverable {
    cursor: pointer;
}

.color-male {
    color: #006657;
}

.color-female {
    color: #380097;
}

a.link-inherit {
    color: inherit;
}

a:hover.link-hover-dark {
    color: #212529;
}

.divider-text {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.divider-text::before, .divider-text::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.divider-text::before {
    margin-right: .5em;
}
.divider-text::after {
    margin-left: .5em;
}

ul {
    /* Reduce ul left margin */
    padding-left: 1.5em;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}

ul.timeline:before {
    content: ' ';
    background: #bebebe;
    display: inline-block;
    position: absolute;
    left: 0.5em;
    width: 0.2em;
    height: 100%;
}

ul.timeline > li {
    margin: 0.75em 0;
    padding-left: 0.2em;
}

ul.timeline > li:first-child {
    padding-top: 0.03em;
}

ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: solid .2em #bebebe;
    left: 0.1em;
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
}

.small-header {
    font-weight: 500;
}

.tools {
    pointer-events: none;
}

.react-transform-wrapper {
    width: 100% !important;
    cursor: move;
}

.animation-up-down-arrow {
    animation: action-up-down-arrow 0.5s infinite alternate;
}

@keyframes action-up-down-arrow {
    0% { transform: translateY(30); }
    100% { transform: translateY(-30%); }
}

.accordion.accordion-clickable > .card > .card-header {
    cursor: pointer;
}

.accordion.accordion-clickable > .card > .card-header.disabled {
    cursor: not-allowed;
    color: #6c757d !important; /* Text muted */
}

.container-svg {
    height: 500px;
}

.container-svg > svg {
    height: 100%;
}
