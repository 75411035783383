/* Greatly inspired by Ross Angus's work: https://codepen.io/ross-angus/pen/jwxMjL */

/* Global styling */
.ascending-tree, .ascending-tree ul, .ascending-tree li {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 50%; /* Ensure all nodes have equal sizes */
    /* white-space: nowrap; /* Prevent wrapping */
}

/* Trunk styling */
.ascending-tree {
    margin: 0;
    text-align: center;
}

/* ul styling */
.ascending-tree, .ascending-tree ul {
    display: table;
    width: 100%;
}

/* li styling */
.ascending-tree li {
    display: table-cell;
    padding: 0;
    vertical-align: bottom;
}

/* Horizontal bar */
.ascending-tree li:after {
    outline: solid .1em #bebebe;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    bottom: .5em;
}

/* Left horizontal bar */
.ascending-tree li:first-child:after {
    left: 50%;
}

/* Right horizontal bar */
.ascending-tree li:last-child:after {
    right: calc(50% + .1em); /* Fix artifacts */
}

/* Text box */
.ascending-tree span {
    border: solid .1em #bebebe;
    border-radius: .2em;
    display: inline-block;
    margin: 0 .2em 1em;
    padding: .2em .5em;
    position: relative;
}

/* Vertical bar */
.ascending-tree span:before,
.ascending-tree span:after {
    outline: solid .1em #bebebe;
    content: "";
    height: .5em;
    left: 50%;
    position: absolute;
}

/* Top vertical bar */
.ascending-tree span:before {
    top: -.6em;
}

/* Bottom vertical bar */
.ascending-tree span:after {
    bottom: -.6em;
}

/* Prune leaves and root */
.ascending-tree span:first-child:before, /* (top) leaves */
.ascending-tree > li > span:after { /* (bottom) root */
    outline: none;
}

/* Hide bottom bar */
.ascending-tree > li:after {
    display: none;
}

/* Clear bottom margin */
.ascending-tree > li > span {
    margin: 0;
}
